import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './hash-anchor.scss';

export function HashAnchor({ name, children, className }) {
  return (
    // todo - review PLAT-3859 disabling
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a className={classnames('hash-anchor', className)} name={name}>
      {children}
    </a>
  );
}

HashAnchor.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};

HashAnchor.defaultProps = {
  children: undefined,
  className: undefined,
};
