import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'site-modules/shared/components/link/link';

import './arrow-link.scss';

export function ArrowLink({ to, children, className, linkClassName, isArrowLeft, color, ...rest }) {
  return (
    <span className={classnames('arrow-link d-block text-nowrap size-16', className, `text-${color}`)}>
      {isArrowLeft && (
        <Fragment>
          <i className="icon-arrow-left4 size-10" aria-hidden />
          &nbsp;
        </Fragment>
      )}
      <Link className={classnames('text-wrap', linkClassName)} to={to} {...rest}>
        {children}
      </Link>
      {!isArrowLeft && (
        <Fragment>
          &nbsp;
          <i className="icon-arrow-right4 size-10" aria-hidden />
        </Fragment>
      )}
    </span>
  );
}

ArrowLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  isArrowLeft: PropTypes.bool,
  color: PropTypes.string,
  linkClassName: PropTypes.string,
};

ArrowLink.defaultProps = {
  to: '',
  children: '',
  className: '',
  isArrowLeft: false,
  color: 'primary-darker',
  linkClassName: null,
};
