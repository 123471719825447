import { PAGE_URL } from 'client/site-modules/shared/constants/page-urls';

export const IMAGE_SIZES = {
  xs: 175,
  sm: 300,
  sm_md: 500,
  md: 600,
  lg: 717,
  xl: 1280,
  max: 1600,
};

export const HERO_SIZE = {
  true: IMAGE_SIZES.sm_md,
  false: IMAGE_SIZES.xl,
};

export const HERO_HEIGHT = {
  true: '295px',
  false: '270px',
};

export const BASE_BREADCRUMBS = {
  HOME: [{ title: 'Home', href: PAGE_URL.HOME }],
  RESEARCH: [{ title: 'Home', href: PAGE_URL.HOME }, { title: 'Research', href: PAGE_URL.RESEARCH }],
  CAR_NEWS: [{ title: 'Home', href: PAGE_URL.HOME }, { title: 'Car News', href: PAGE_URL.CAR_NEWS }],
  CAR_NEWS_SEO: [{ title: 'Home', href: PAGE_URL.HOME }, { title: 'News', href: PAGE_URL.CAR_NEWS }],
};

// Link location for jump link at bottom of core page review module to related first content module
export const RELATED_ARTICLES_JUMP_LINK_LOCATION = 'related-articles';

export const CAR_NEWS_LANDING_CONTENT_PATH = 'research/car-news/index-redesign';
export const EV_NEWS_WIDGET_CONTENT_PATH = 'electric-car/articles/ev-widget';
export const CPO_NEWS_WIDGET_CONTENT_PATH = 'editorial/includes/cpo/cpo-widget';
