import { TrackingEvent } from 'client/tracking/event';
import { TrackingConstant } from 'client/tracking/constant';

export const HomepageAdExtensionMap = {
  hero_module_link_click: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.ACTION_VIEW_CONTENT
  ),

  hero_module_link_click_out: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_WEBSITE_CLICKOUT,
    TrackingConstant.OEM_SITE_CLICKOUT
  ),

  vehicle_showcase_click_in: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.ACTION_VIEW_CONTENT
  ),

  vehicle_showcase_click_out: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_WEBSITE_CLICKOUT,
    TrackingConstant.OEM_SITE_CLICKOUT
  ),
};
