import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { get } from 'lodash';
import { connectToModel, bindToPath } from 'client/data/luckdragon/redux/react-binding';
import { CmsEntities, bindToContent } from 'client/data/models/cms';
import { PageModel } from 'client/data/models/page';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { ArrowLink } from 'site-modules/shared/components/arrow-link/arrow-link';
import {
  CAR_NEWS_LANDING_CONTENT_PATH,
  EV_NEWS_WIDGET_CONTENT_PATH,
  CPO_NEWS_WIDGET_CONTENT_PATH,
} from 'client/site-modules/shared/constants/editorial/editorial';
import {
  EditorialEntities,
  EditorialModel,
  buildMakeArticlesPath,
  buildTypeArticlesPath,
} from 'client/data/models/editorial';
import { MinHeightWrapper } from 'site-modules/shared/components/min-height-wrapper/min-height-wrapper';
import { getEntriesToRender, getNewsWidgetTitle } from 'client/site-modules/shared/utils/news-widget';
import { Link } from 'site-modules/shared/components/link/link';
import { getTitleWithNoOrphans } from 'site-modules/shared/utils/get-title-with-no-orphans';

import { NewsWidgetCard } from './news-widget-card';

import './news-widget.scss';

export function NewsWidgetUI({
  newsContent,
  newsArticles,
  makeName,
  type,
  pathname,
  withDivider,
  withFallback,
  className,
  isMobile,
}) {
  const title = getNewsWidgetTitle({ makeName, type, newsContent });
  const { articlesEntries, videoEntry } = getEntriesToRender({
    content: newsContent,
    newsArticles,
    pathname,
    makeName,
    fallbackToVehicleAgnosticContent: !makeName || withFallback,
  });

  return articlesEntries.length || videoEntry ? (
    <Fragment>
      {withDivider && <hr className="mt-1_5 mb-2" />}
      <section className={classnames('news-widget', className)} data-tracking-parent="edm-entry-news-widget">
        <Link to="/car-news/">
          <h2 className="text-capitalize heading-3 mb-0_5 mb-md-1_5">{getTitleWithNoOrphans(title)}</h2>
        </Link>
        <MinHeightWrapper componentType="NEWS_WIDGET" className="news-widget-grid mb-1_5">
          {articlesEntries.map((entry, index) => (
            <NewsWidgetCard
              key={entry.id || entry.title}
              entry={entry}
              isNewsArticleEntry={entry.isNewsArticlesEntryType}
              trackingValue={`frame_${index + 1}`}
              isMobile={isMobile}
            />
          ))}
          {videoEntry && (
            <NewsWidgetCard
              entry={videoEntry}
              isVideoCard
              trackingValue={`frame_${articlesEntries.length + 1}`}
              isMobile={isMobile}
            />
          )}
        </MinHeightWrapper>
        <ArrowLink to="/car-news/" data-tracking-id="news_widget_see_all_news">
          See all car news
        </ArrowLink>
      </section>
    </Fragment>
  ) : null;
}

NewsWidgetUI.propTypes = {
  newsContent: CmsEntities.Content,
  newsArticles: EditorialEntities.Articles,
  type: PropTypes.string,
  pathname: PropTypes.string,
  makeName: PropTypes.string,
  withDivider: PropTypes.bool,
  withFallback: PropTypes.bool,
  className: PropTypes.string,
  isMobile: PropTypes.bool,
};

NewsWidgetUI.defaultProps = {
  newsContent: DEFAULT_CONTENT,
  newsArticles: [],
  type: '',
  pathname: '',
  makeName: '',
  withDivider: false,
  withFallback: false,
  className: null,
  isMobile: false,
};

const mapStateToProps = state => ({
  isMobile: get(state, 'mobile'),
});

export const NewWidgetWrapper = connect(mapStateToProps)(NewsWidgetUI);

export const NewsWidget = connectToModel(NewWidgetWrapper, {
  newsContent: bindToContent(CAR_NEWS_LANDING_CONTENT_PATH),
  pathname: bindToPath('location.pathname', PageModel),
});

export const MakeNewsWidget = connectToModel(NewWidgetWrapper, {
  newsContent: bindToContent(CAR_NEWS_LANDING_CONTENT_PATH),
  newsArticles: bindToPath(({ makeSlug }) => buildMakeArticlesPath(makeSlug), EditorialModel, articlesData =>
    get(articlesData, 'results', [])
  ),
});

export const TypeNewsWidget = connectToModel(NewWidgetWrapper, {
  newsArticles: bindToPath(({ type }) => buildTypeArticlesPath(type), EditorialModel, articlesData =>
    get(articlesData, 'results', [])
  ),
});

export const EvNewsWidget = connectToModel(NewWidgetWrapper, {
  newsContent: bindToContent(EV_NEWS_WIDGET_CONTENT_PATH, 'ev-widget'),
});

export const CpoNewsWidget = connectToModel(NewsWidgetUI, {
  newsContent: bindToContent(CPO_NEWS_WIDGET_CONTENT_PATH, 'cpo-widget'),
});
