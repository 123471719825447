import { head, uniqBy, concat } from 'lodash';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { getUserFriendlyTypeName } from 'site-modules/shared/utils/upper-funnel/type-mapping';
import { getCurrentDate } from 'client/site-modules/shared/utils/date-utils';
import { TIME } from 'client/utils/time';

const NEWS_CARDS_NUM = 4;
const DEFAULT_WIDGET_TITLE = 'Latest car news from our experts';

const currentDate = getCurrentDate();

export const filterRelatedContent = (vehicles, name) =>
  Array.isArray(vehicles) && vehicles.some(({ make, model }) => [make, model].includes(name));

export const getArticleTitle = article => (article.isNewsArticlesEntryType ? article.title : article['promo-text']);

export const getArticleDate = article => {
  const date = article.isNewsArticlesEntryType
    ? article.updateDate || article.publishDate
    : article['article-date'] || article.uploadDate;
  return new Date(date);
};

export const sortArticlesByDate = (article1, article2) => getArticleDate(article2) - getArticleDate(article1);

export const isArticleNewerThanYear = articleDate => currentDate - articleDate < TIME.MS.ONE_YEAR;

export const filterSamePage = (entryUrl, pathname) => entryUrl !== pathname;

export function getEntriesToRender({
  content = DEFAULT_CONTENT,
  newsArticles = [],
  makeName,
  modelName,
  fallbackToVehicleAgnosticContent = false,
  pathname = '',
  excludedArticles = [],
}) {
  const isEvWidget = content.id === 'ev-widget';
  const isCpoWidget = content.id === 'cpo-widget';

  const mainContent = content.child('main');
  const articlesSourceEntries =
    isEvWidget || isCpoWidget
      ? content.children().filter(({ id }) => id.includes('article'))
      : concat(mainContent.child('car-news').children(), mainContent.child('top-stories').children());

  const articles = articlesSourceEntries
    .map(entry => ({
      id: entry.id,
      ...entry,
      ...entry.getAllMetadata(),
      url: entry.metadata('link-url').value(),
    }))
    .filter(({ url }) => !excludedArticles.includes(url));

  const makeRelatedArticles = articles.filter(({ vehicles }) => filterRelatedContent(vehicles, makeName));

  const modelRelatedArticles = makeRelatedArticles.filter(({ vehicles }) => filterRelatedContent(vehicles, modelName));

  const videosSourceEntries =
    isEvWidget || isCpoWidget
      ? content.children().filter(({ id }) => id.includes('video'))
      : mainContent.child('video').children();

  const videos = videosSourceEntries.map(entry => ({
    id: entry.id,
    ...entry,
    ...entry.getAllMetadata(),
    url: entry.metadata('anchorURL').value(),
  }));

  const makeRelatedVideos = videos.filter(
    ({ url, vehicles }) => filterRelatedContent(vehicles, makeName) && filterSamePage(url, pathname)
  );
  const modelRelatedVideos = makeRelatedVideos.filter(
    ({ url, vehicles }) => filterRelatedContent(vehicles, modelName) && filterSamePage(url, pathname)
  );
  const filteredNewsArticles = newsArticles.filter(
    ({ relativeUrl }) => !excludedArticles.includes(relativeUrl) && filterSamePage(relativeUrl, pathname)
  );

  const videoEntry =
    head(modelRelatedVideos) || head(makeRelatedVideos) || (fallbackToVehicleAgnosticContent ? head(videos) : null);

  if (videoEntry) {
    videoEntry.isVideoEntry = true;
  }

  const isTitleMakeModelRelevant = title =>
    title && ((makeName && title.includes(makeName)) || (modelName && title.includes(modelName)));

  const widgetArticles = uniqBy(
    [
      ...modelRelatedArticles.slice(0, NEWS_CARDS_NUM),
      ...makeRelatedArticles.slice(0, NEWS_CARDS_NUM),
      ...filteredNewsArticles
        .slice(0, NEWS_CARDS_NUM)
        .map(article => ({ ...article, isNewsArticlesEntryType: true, url: article.relativeUrl })),
      ...(fallbackToVehicleAgnosticContent ? articles : []),
    ],
    'url'
  ).sort((article1, article2) => {
    // The logic of prioritizing articles in the following order:
    // 1) Make Model, or Make, mentioned in the URL
    // Make Model and Make in the URL should be the first priority up to 1 year post date publish/date modified date
    // 2) Date of publication or modification, if it’s been modified - from most recent to least
    // If the date pub/modified is over a year newer than the articles in criteria #1, then these articles should be prioritized above.

    const title1 = getArticleTitle(article1);
    const title2 = getArticleTitle(article2);
    const articleDate1 = getArticleDate(article1);
    const articleDate2 = getArticleDate(article2);

    if (isTitleMakeModelRelevant(title1) && !isTitleMakeModelRelevant(title2) && isArticleNewerThanYear(articleDate1)) {
      return -1;
    } else if (
      !isTitleMakeModelRelevant(title1) &&
      isTitleMakeModelRelevant(title2) &&
      isArticleNewerThanYear(articleDate2)
    ) {
      return 1;
    }

    return articleDate2 - articleDate1;
  });

  const articlesEntries = widgetArticles.slice(0, videoEntry ? NEWS_CARDS_NUM - 1 : NEWS_CARDS_NUM);

  return {
    articlesEntries,
    videoEntry,
  };
}

export const getNewsWidgetTitle = ({ makeName, modelName, type, newsContent }) => {
  const isNewsLandingContentPath = newsContent.id && newsContent.id.includes('car-news/index');

  if (modelName) {
    return `Edmunds Latest ${makeName} ${modelName} News`;
  } else if (makeName) {
    return `Edmunds Latest ${makeName} News`;
  } else if (type) {
    return `Edmunds Latest ${getUserFriendlyTypeName(type)} News`;
  } else if (isNewsLandingContentPath) {
    return DEFAULT_WIDGET_TITLE;
  }

  return newsContent.title || DEFAULT_WIDGET_TITLE;
};
