import PropTypes from 'prop-types';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { EdmundsAPI } from 'client/data/api/api-client';
import { withMetrics } from 'client/data/api/api-metrics';

const ArticleEntity = PropTypes.shape({
  title: PropTypes.string,
  image: PropTypes.string,
  relativeUrl: PropTypes.string,
  summary: PropTypes.string,
  publishDate: PropTypes.string,
  updateDate: PropTypes.string,
  author: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
});

const ArticlesEntity = PropTypes.arrayOf(ArticleEntity);

const VideoEntity = PropTypes.shape({
  type: PropTypes.string,
  url: PropTypes.string,
  title: PropTypes.string,
  publishDate: PropTypes.string,
});

const VideosEntity = PropTypes.arrayOf(VideoEntity);

export const EditorialEntities = {
  Article: ArticleEntity,
  Articles: ArticlesEntity,
  ArticlesData: PropTypes.shape({
    totalNumber: PropTypes.number,
    totalPages: PropTypes.number,
    results: ArticlesEntity,
  }),
  Video: VideoEntity,
  Videos: VideosEntity,
  VideosData: PropTypes.shape({
    totalNumber: PropTypes.number,
    totalPages: PropTypes.number,
    results: VideosEntity,
  }),
};

export const PAGE_SIZE = 15;
export const AUTHOR_CATEGORY_PAGE_SIZE = 10;

const TYPE_MAPPING = {
  ev: 'electric-car',
};

export const buildMakeArticlesPath = (make, pageNum = 1) => `makes["${make}"].articles.pages["${+pageNum}"]`;

export const buildTypeArticlesPath = (type, pageNum = 1, pageSize = PAGE_SIZE, useTypeMapping = true) =>
  `types["${
    useTypeMapping ? TYPE_MAPPING[type] || type : type
  }"].articles.pageSize["${pageSize}"].pages["${+pageNum}"]`;

export const buildAuthorArticlesPath = (authorName, pageNum = 1) =>
  `authors["${authorName}"].articles.pages["${+pageNum}"]`;

export const buildAuthorVideosPath = (authorName, pageNum = 1) =>
  `authors["${authorName}"].videos.pages["${+pageNum}"]`;

export const EditorialModel = createModelSegment('editorial', [
  {
    path: 'authors["{authorName}"].articles.pages["{pageNum}"]',
    async resolve(match, context) {
      const { authorName, pageNum } = match;

      return withMetrics(EdmundsAPI, context)
        .fetchJson(
          `/editorial/v3/authors/${authorName}/articles?pagenum=${pageNum}&pagesize=${AUTHOR_CATEGORY_PAGE_SIZE}`
        )
        .catch(() => ({}));
    },
  },
  {
    path: 'authors["{authorName}"].videos.pages["{pageNum}"]',
    async resolve(match, context) {
      const { authorName, pageNum } = match;

      return withMetrics(EdmundsAPI, context)
        .fetchJson(
          `/editorial/v3/authors/${authorName}/videos?pagenum=${pageNum}&pagesize=${AUTHOR_CATEGORY_PAGE_SIZE}`
        )
        .then(response => ({
          ...response,
          results: response.results.filter(video => video.title.toLowerCase() !== 'private video'),
        }))
        .catch(() => ({}));
    },
  },
  {
    path: 'makes["{make}"].articles.pages["{pageNum}"]',
    async resolve(match, context) {
      const { make, pageNum } = match;
      const path = `/editorial/v3/makes/${make}/articles?pagenum=${pageNum}&pagesize=${PAGE_SIZE}`;

      return withMetrics(EdmundsAPI, context)
        .fetchJson(path)
        .catch(() => ({}));
    },
  },
  /**
   * Example: https://qa-11-www.edmunds.com/gateway/api/editorial/v3/tags/ev/articles?pagenum=1&pagesize=15
   * @see buildTypeArticlesPath
   */
  {
    path: 'types["{type}"].articles.pageSize["{pageSize}"].pages["{pageNum}"]',
    async resolve({ type, pageNum, pageSize }, context) {
      const path = `/editorial/v3/tags/${type}/articles?pagenum=${pageNum}&pagesize=${pageSize}`;

      return withMetrics(EdmundsAPI, context)
        .fetchJson(path)
        .catch(() => ({}));
    },
  },
]);
