import { TrackingEvent } from 'client/tracking/event';
import { TrackingConstant } from 'client/tracking/constant';
import { TAB_IDS } from 'client/site-modules/shared/constants/search-by-module';

export const getSearchByModuleTrackingMap = mmzTrackingIds => ({
  [mmzTrackingIds.submitByTabNameTrackingId(TAB_IDS.MAKE_MODEL_TAB)]: TrackingEvent.actionEnd(
    TrackingConstant.ACTION_MMZ_SELECT,
    TrackingConstant.SUBMIT_MMZ
  ),

  [mmzTrackingIds.submitByTabNameTrackingId(TAB_IDS.TYPE_TAB)]: TrackingEvent.actionEnd(
    TrackingConstant.ACTION_TYPE_SELECT,
    TrackingConstant.SUBMIT_VEHICLE_TYPE
  ),

  [mmzTrackingIds.submitByTabNameTrackingId(TAB_IDS.VEHICLE_PRICE_TAB)]: TrackingEvent.actionEnd(
    TrackingConstant.ACTION_PRICE_RANGE_SELECT,
    TrackingConstant.SUBMIT_PRICE
  ),

  [mmzTrackingIds.submitByTabNameTrackingId(TAB_IDS.LOAN_PAYMENT_TAB)]: TrackingEvent.actionEnd(
    TrackingConstant.ACTION_LOAN_PAYMENT_SELECT,
    TrackingConstant.SUBMIT_LOAN_PAYMENT
  ),

  [mmzTrackingIds.submitByTabNameTrackingId(TAB_IDS.LEASE_PAYMENT_TAB)]: TrackingEvent.actionEnd(
    TrackingConstant.ACTION_LEASE_PAYMENT_SELECT,
    TrackingConstant.SUBMIT_LEASE_PAYMENT
  ),

  [mmzTrackingIds.zipInputTracking()['data-tracking-id']]: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_MMZ_SELECT,
    TrackingConstant.ZIP_CODE_ENTRY
  ),

  [mmzTrackingIds.zipInputByTabTracking(TAB_IDS.MAKE_MODEL_TAB)['data-tracking-id']]: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_MMZ_SELECT,
    TrackingConstant.ZIP_CODE_ENTRY
  ),

  [mmzTrackingIds.zipInputByTabTracking(TAB_IDS.TYPE_TAB)['data-tracking-id']]: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_TYPE_SELECT,
    TrackingConstant.ZIP_CODE_ENTRY
  ),

  [mmzTrackingIds.zipInputByTabTracking(TAB_IDS.VEHICLE_PRICE_TAB)['data-tracking-id']]: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_PRICE_RANGE_SELECT,
    TrackingConstant.ZIP_CODE_ENTRY
  ),

  [mmzTrackingIds.zipInputByTabTracking(TAB_IDS.LOAN_PAYMENT_TAB)['data-tracking-id']]: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_LOAN_PAYMENT_SELECT,
    TrackingConstant.ZIP_CODE_ENTRY
  ),

  [mmzTrackingIds.zipInputByTabTracking(TAB_IDS.LEASE_PAYMENT_TAB)['data-tracking-id']]: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_LEASE_PAYMENT_SELECT,
    TrackingConstant.ZIP_CODE_ENTRY
  ),

  [mmzTrackingIds.tabSelectTracking(TAB_IDS.MAKE_MODEL_TAB)]: TrackingEvent.actionStart(
    TrackingConstant.ACTION_MMZ_SELECT,
    TrackingConstant.MAKE_MODEL_TAB_SELECT
  ),

  [mmzTrackingIds.tabSelectTracking(TAB_IDS.TYPE_TAB)]: TrackingEvent.actionStart(
    TrackingConstant.ACTION_TYPE_SELECT,
    TrackingConstant.VEHICLE_TYPE_TAB_SELECT
  ),

  [mmzTrackingIds.tabSelectTracking(TAB_IDS.PRICE_TAB)]: TrackingEvent.actionStart(
    TrackingConstant.ACTION_PRICE_RANGE_SELECT,
    TrackingConstant.PRICE_TAB_SELECT
  ),

  [mmzTrackingIds.tabSelectTracking(TAB_IDS.VEHICLE_PRICE_TAB)]: TrackingEvent.actionStart(
    TrackingConstant.ACTION_PRICE_RANGE_SELECT,
    TrackingConstant.PRICE_RADIO_SELECT
  ),

  [mmzTrackingIds.tabSelectTracking(TAB_IDS.LOAN_PAYMENT_TAB)]: TrackingEvent.actionStart(
    TrackingConstant.ACTION_LOAN_PAYMENT_SELECT,
    TrackingConstant.LOAN_PAYMENT_RADIO_SELECT
  ),

  [mmzTrackingIds.tabSelectTracking(TAB_IDS.LEASE_PAYMENT_TAB)]: TrackingEvent.actionStart(
    TrackingConstant.ACTION_LEASE_PAYMENT_SELECT,
    TrackingConstant.LEASE_PAYMENT_RADIO_SELECT
  ),

  [mmzTrackingIds.typeSelectTrackingId]: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_TYPE_SELECT,
    TrackingConstant.VEHICLE_TYPE_SELECT
  ),

  [mmzTrackingIds.minPriceSelectTracking(TAB_IDS.TYPE_TAB)]: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_TYPE_SELECT,
    TrackingConstant.MIN_PRICE_SELECT
  ),

  [mmzTrackingIds.maxPriceSelectTracking(TAB_IDS.TYPE_TAB)]: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_TYPE_SELECT,
    TrackingConstant.MAX_PRICE_SELECT
  ),

  [mmzTrackingIds.minPriceSelectTracking(TAB_IDS.VEHICLE_PRICE_TAB)]: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_PRICE_RANGE_SELECT,
    TrackingConstant.MIN_PRICE_SELECT
  ),

  [mmzTrackingIds.maxPriceSelectTracking(TAB_IDS.VEHICLE_PRICE_TAB)]: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_PRICE_RANGE_SELECT,
    TrackingConstant.MAX_PRICE_SELECT
  ),
  [mmzTrackingIds.maxPriceSelectTracking(TAB_IDS.LOAN_PAYMENT_TAB)]: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_LOAN_PAYMENT_SELECT,
    TrackingConstant.MAX_PRICE_SELECT
  ),
  [mmzTrackingIds.maxPriceSelectTracking(TAB_IDS.LEASE_PAYMENT_TAB)]: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_LEASE_PAYMENT_SELECT,
    TrackingConstant.MAX_PRICE_SELECT
  ),
  [mmzTrackingIds.buyOnlineSelectTracking]: TrackingEvent.actionStart(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.BUY_ONLINE_SELECT
  ),
  [mmzTrackingIds.buyOnlineUnselectTracking]: TrackingEvent.actionStart(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.BUY_ONLINE_UNSELECT
  ),
});
