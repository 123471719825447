import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import './ad-label.scss';

const DEFAULT_STYLES = {
  width: '20px',
  position: 'absolute',
  right: '5px',
  top: '5px',
};

export function AdLabel({ adLabel, classes, styles }) {
  const adLabelStyles = {
    ...DEFAULT_STYLES,
    ...styles,
  };

  return (
    <Fragment>
      <span className="sr-only">Advertisement</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 14"
        width={20}
        className={`site-served-label ${classes}`}
        style={adLabelStyles}
        aria-hidden
      >
        <text x={4} y={10} fill="#fff" style={{ fontSize: '10px' }}>
          {adLabel}
        </text>
      </svg>
    </Fragment>
  );
}

AdLabel.defaultProps = {
  adLabel: 'Ad',
  classes: '',
  styles: null,
};

AdLabel.propTypes = {
  adLabel: PropTypes.string,
  classes: PropTypes.string,
  styles: PropTypes.shape({}),
};
