import { PUB_STATES } from 'client/constants/pub-states';
import { get } from 'lodash';
import { getCoreUrl, getReviewUrl } from 'site-modules/shared/utils/core-link-constructor';
import {
  generateSrpLinkCPOMakeModelYear,
  generateSrpLinkMakeModel,
  srpLinkBuilder,
} from 'site-modules/shared/utils/srp-link-constructor';

export function generateCoreUrl(make, model, selectedYear, latestNonPreprodYear, redirectOldToReview) {
  const makeSlug = get(make, 'slug', '').toLowerCase();
  const modelSlug = get(model, 'slug', '').toLowerCase();
  const year = get(selectedYear, 'value', latestNonPreprodYear);
  if (!makeSlug || !modelSlug || !year) return null;

  return redirectOldToReview && get(selectedYear, 'isUsed')
    ? getReviewUrl({
        makeSlug,
        modelSlug,
        year,
      })
    : getCoreUrl({
        makeSlug,
        modelSlug,
        year,
      });
}

export function generateCPOSrpLink(make, model, year) {
  const makeName = get(make, 'slug');
  const modelName = get(model, 'slug');
  const selectedYear = get(year, 'value');
  if (!makeName || !modelName || !selectedYear) return null;
  return generateSrpLinkCPOMakeModelYear(makeName, modelName, year.value);
}

export function generateUsedSrpLink(make, model) {
  const makeName = get(make, 'slug');
  const modelName = get(model, 'slug');
  if (!makeName || !modelName) return null;
  return generateSrpLinkMakeModel(makeName, modelName, 'used');
}

export function generateTrackingIds(pageName, ddTypePrefix = 'mmy') {
  return {
    submitTrackingId: `${pageName}_${ddTypePrefix}_submit`,
    submitByTabNameTrackingId: tabName => `${pageName}_${ddTypePrefix}_${tabName}_submit`,
    makeSelectTrackingId: `${pageName}_${ddTypePrefix}_make_select`,
    modelSelectTrackingId: `${pageName}_${ddTypePrefix}_model_select`,
    zipInputTracking: () => ({
      'data-tracking-id': `${pageName}_${ddTypePrefix}_zip_code_entry`,
    }),
    zipInputByTabTracking: tabName => ({
      'data-tracking-id': `${pageName}_${ddTypePrefix}_${tabName}_zip_code_entry`,
    }),
    submodelSelectTrackingId: `${pageName}_${ddTypePrefix}_submodel_select`,
    yearSelectTrackingId: `${pageName}_${ddTypePrefix}_year_select`,
    typeSelectTrackingId: `${pageName}_${ddTypePrefix}_type_select`,
    tabSelectTracking: tabName => `${pageName}_${ddTypePrefix}_${tabName}_select`,
    minPriceSelectTracking: tabName => `${pageName}_${ddTypePrefix}_${tabName}_min_price_select`,
    maxPriceSelectTracking: tabName => `${pageName}_${ddTypePrefix}_${tabName}_max_price_select`,
    buyOnlineSelectTracking: `${pageName}_${ddTypePrefix}_buy_online_select`,
    buyOnlineUnselectTracking: `${pageName}_${ddTypePrefix}_buy_online_unselect`,
  };
}

export const LINKS_MAP = {
  CORE_PAGE: 'corePage',
  SRP: 'srp',
  USED_SRP: 'usedSRP',
  REVIEW_PAGE: 'review',
  CPO_SRP: 'cpoSRP',
};

export const LINKS = {
  [LINKS_MAP.CORE_PAGE]: ({ make, model, year, latestNonPreprodYear }) =>
    generateCoreUrl(make, model, year, latestNonPreprodYear),
  [LINKS_MAP.USED_SRP]: ({ make, model }) => generateUsedSrpLink(make, model),
  [LINKS_MAP.SRP]: ({ params }) => srpLinkBuilder(params),
  [LINKS_MAP.REVIEW_PAGE]: ({ make, model, year, latestNonPreprodYear }) =>
    generateCoreUrl(make, model, year, latestNonPreprodYear, true),
  [LINKS_MAP.CPO_SRP]: ({ make, model, year }) => generateCPOSrpLink(make, model, year),
};

export const DEFAULT_PUB_STATES = [PUB_STATES.NEW, PUB_STATES.NEW_USED, PUB_STATES.USED];
