import { get } from 'lodash';
import { EventToolbox } from 'client/utils/event-toolbox';
import { CAROUSEL_EVENTS } from 'site-modules/shared/components/carousel/events';

export const DIRECTIONS = {
  HORIZONTAL: {
    PREV: 'right',
    NEXT: 'left',
  },
  VERTICAL: {
    PREV: 'down',
    NEXT: 'up',
  },
};

/**
 * Defines carousel counter className
 * @param {Boolean|String} pageCounter Page counter minimum breakpoint name, e.g. xs, sm, etc.
 */
export function counterClassName(pageCounter) {
  if (!pageCounter) return '';
  return pageCounter === true || pageCounter === 'xs' ? 'page-counter' : `page-counter-${pageCounter}`;
}

export function calcNextSlide(currentSlide, total, direction, vertical, infinite) {
  const movingForward =
    (vertical && direction === DIRECTIONS.VERTICAL.NEXT) || (!vertical && direction === DIRECTIONS.HORIZONTAL.NEXT);
  const step = movingForward ? 1 : -1;
  const nextSlide = currentSlide + step;
  const leftBound = 0;
  const rightBound = total - 1;
  if (nextSlide < leftBound) {
    return infinite ? rightBound : leftBound;
  } else if (nextSlide > rightBound) {
    return infinite ? leftBound : rightBound;
  }
  return nextSlide;
}

/**
 * Fires swipe custom event with collected data
 *
 * @param config {object}
 * @property props {object}
 * @property slider {object}
 * @property element {element}
 * @property nextSlide {number}
 */
export function fireSwipeEvent({ props, slider, element, nextSlide }) {
  const photoType = get(props, 'data-carousel-photo-type', '');
  const { contentType, noAdsRefresh, skipSwipeTracking } = props;

  const total = get(slider, 'innerSlider.state.slideCount', 0);
  const currentSlide = get(slider, 'innerSlider.state.currentSlide', 0);

  const contentDetails = props.contentDetails || contentType;
  const customEventData = props.customEventData || {};

  EventToolbox.fireCustomEvent(CAROUSEL_EVENTS.SWIPE, {
    target: element,
    data: {
      currentSlide,
      nextSlide,
      total,
      contentType,
      contentDetails,
      customEventData,
      photoType,
      noAdsRefresh,
      skipSwipeTracking,
    },
  });
}
