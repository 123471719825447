import { EventToolbox } from 'client/utils/event-toolbox';
import { getCreativeId } from 'client/engagement-handlers/helper';
import { getAvailabilityZone } from 'client/utils/aws';

export function fireAdLoadTracking(linkText, el, clickCorrelatorTs = '', position = '', lineItemId = '', customFields) {
  if (el) {
    EventToolbox.fireTrackAction({
      event_type: 'action_completed',
      event_data: {
        action_name: 'native_ad_load',
        action_category: 'system',
        value: linkText,
        action_cause: 'page_load',
        creative_id: getCreativeId(el),
        line_item_id: lineItemId,
        click_correlator_ts: clickCorrelatorTs,
        position,
        region: getAvailabilityZone(),
        ...customFields,
      },
    });
  }
}

export function fireFeaturedSpotlightAdLoadTracking(el) {
  if (el) {
    EventToolbox.fireTrackAction({
      event_type: 'action_completed',
      event_data: {
        action_name: 'ad_load',
        action_category: 'system',
        action_cause: 'page_load',
        creative_id: getCreativeId(el),
      },
    });
  }
}
